import React, {useRef,useState,useEffect} from 'react'
import { useSpring, animated } from '@react-spring/web'
import { useMove } from '@use-gesture/react'
import logoMask from './assets/reweave-invert.png';
import rMask from './assets/r-invert.png';
import useInterval from '../../hooks/useInterval.js';


export const meta = {
	title: 'Mouse Moiree'
}

const width = 2000;
const height = width * 0.66;

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

const Exp = ({entry}) => {

    const [{ x, y, negX, negY ,rotation}, api] = useSpring(() => ({ x: 0, y: 0, negX : -5,negY : -5,rotation:0 }))

    const size = useWindowSize();
    const isMobile = window.innerWidth < 600;
  let maskToUse = isMobile ? rMask : logoMask;
  
  const mouseMoved = useRef(false);
  const isPortrait = useRef(window.innerWidth < window.innerHeight)
  const [orientationPortrait,setOrientationPortrait] = useState(isPortrait.current);


  useInterval(()=>{
      if(!mouseMoved.current){
        // Randomise

        let x = -10 + Math.round(Math.random() * 20);
        let y = -10 + Math.round(Math.random() * 20)
        api.start({ 
          rotation : 180 + ( -20 + Math.round(Math.random() * 40) ),
          x : x,
          y : y,
          negX : -x,
          negY:-y,
          immediate:false
        });
      }

      isPortrait.current = window.innerWidth < window.innerHeight;

      if(isPortrait.current){
        // Use R
        setOrientationPortrait(true);
      }else{
         // Dont use R
         setOrientationPortrait(false);
      }
  },1200);

    const w = size.width;
    const h = size.height;

      // How much can the image move around?
      const nudge = w * 0.2;
      let scaleX = (w + nudge) / w;
      let scaleY = (h + nudge) / h;
      let scale = (Math.max(scaleX,scaleY));


  // Set the drag hook and define component movement based on gesture data
  const bind = useMove(({ down, xy: [x, y] }) => {

      /*const pllx = 1.2;
   
      let nudgeX = x/w * nudge;
      let nudgeY = y/h * nudge * pllx;

      let rotation = 0 + (x/w * 180);

    api.start({ x: nudgeX, y:nudgeY, negX:-nudgeX, negY:-nudgeY ,rotation:0,immediate:false})*/

    mouseMoved.current = true;

      const maxMove = width * 0.01;
      const pllx = 1.2;
      
      let w = window.innerWidth;
      let h = window.innerHeight;

      let nudgeX = (x - (w/2))/w * maxMove;
      let nudgeY = (y - (h/2))/h * maxMove;

      let progress = x/w;
      let rotation = 180 + ( -20 + Math.round(progress * 40) );
      let prog = y/h;

      api.start({ x: nudgeX,y: nudgeY,negX: -nudgeX, negY:-nudgeY,rotation:rotation,immediate:false});
  })


      const spacer = isMobile ? Math.round(w/200) : Math.round(w/350);
       const spacerY = spacer;
      
      const cols = Math.ceil(w/spacer);
      const rows = Math.ceil(h/spacer);

      console.log(cols,rows);

      const col = {
        height:`100vh`,
        width:1,
        background:'white',
        position:'absolute',
        top:0
      }

      const row = {
        width:`100vw`,
        height:1,
        background:'white',
        position:'absolute',
        left:0
      }

      

      const mask = {
        WebkitMask :  `url(${maskToUse}) center center no-repeat`,
        maskImage: `url(${maskToUse}) no-repeat`,
        WebkitMaskSize :  isMobile ? `40% auto` : `60% auto`,
        maskSize: isMobile ? `40% auto` : `60% auto`
      }

      return <div {...bind()} style={{perspective:500,position:'relative',height:`100%`,width:`100%`,overflow:'hidden',background:'transparent'}}>
      <div style={{perspective:800,'position':'absolute',top:0,left:0, right:0,bottom:0,clipPathoff: `polygon(50% 40%, 40% 60%, 60% 60%)`}}>
      
         
      <animated.div style={{...mask,position:'absolute',width:`100vw`,height:h,display:`block`,top:0,left:0,transformOrigin:'50%,50%',x:negX,y:negY}}>
        {[...new Array(cols)].map((i,ix)=> <div key={`col_${ix}`} style={{...col,left:spacer * ix}} />)}
      </animated.div>
   
      <animated.div style={{...mask,position:'absolute',width:`100vw`,height:h,display:`block`,top:0,left:0,transformOrigin:'50%,50%',x:x,y:y}}>
        <animated.div style={{position:'absolute',top:0,left:0,width:'100%',height:'100%',transformOrigin:`50% 50%`,rotateZ :rotation}}>
        {[...new Array(rows)].map((i,ix)=> <div key={`row_${ix}`} style={{...row,top:spacerY * ix}} />)}
        </animated.div>
      </animated.div>

      </div>
      </div>
}

export default Exp;
