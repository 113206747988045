import React , {useEffect,useState} from 'react'
import {  NavLink, Link, useHistory, useLocation} from 'react-router-dom';

import {useBodyClass} from '../hooks/useBodyClass.js';

import {Tooltip} from '../components/Tooltip.js';
import {Presence} from '../components/Presence.js';

function BgMedia({media}){


  if(media.url.match('.mp4')){
    return <video 
            src={media.url}
            initial={false}
            autoPlay={true}
            controls={false}
            playsInline={true}
            muted={true}
            loop={true}
           />
  }else{
    return <img src={`${media.url}&w=600`} />
  }
  

}

function Hover({src,callback=(val)=>{},children}){

	return <div onMouseEnter={()=>callback(src)} onMouseLeave={()=>callback(null)}>{children}</div>
}

export const Index = ({entry,projects}) => {

		let [tooltipmedia,setTooltip] = React.useState(null);

      return (<div>

        <Tooltip>
      	<Presence isVisible={tooltipmedia}>
      	
		{tooltipmedia && <BgMedia key={tooltipmedia} media={tooltipmedia} />}

		</Presence>
        </Tooltip>

          <div className="Site-index">
      		{projects.map((p,ix)=><div key={ix}>{p.uri && <Hover src={p.image} callback={setTooltip}><span>{ix < 9 ? '0' : ''}{ix + 1}</span> <Link to={p.uri}>{p.title}</Link></Hover>}</div>)}
      		</div>
          </div>
      )
}
