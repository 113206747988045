import React , {useEffect,useState} from 'react'
import {Tooltip} from "./Tooltip";

import {useBodyClass} from '../hooks/useBodyClass';

import { FaPlay } from "react-icons/fa";
import { GrLinkDown } from "react-icons/gr";

const Icon = GrLinkDown;

export const SmartCursor = ({state,active}) => {

    let stateName = `Body--cursor-state-${state}`
    useBodyClass(stateName);

    let activeName = `Body--cursor-${active ? 'active' : 'inactive' }`
    useBodyClass(activeName);
                                                               
   return (
    <Tooltip className="Tooltip--cursor">
        <Icon />
    </Tooltip>
    )
}
