import React, { useState, useEffect, useRef } from 'react';

import {  Link } from 'react-router-dom';
import {  motion } from "framer-motion";
//import { Media } from 'react-breakpoints';
import {Helmet} from "react-helmet";

import anime from "animejs";
import Moment from 'react-moment';



/*

Local

*/

import ReweaveLogo from './components/Logo';
import {Ticker} from './components/Ticker.js';
import {StackGallery} from './components/StackGallery.js';
import {AnimatedText} from './components/AnimatedText.js';
import {PrismicAPI} from './components/Prismic.js';
import {TouchClass} from './components/IsTouch.js';
import {Presence} from './components/Presence.js';
import {Sort} from './components/Sort.js';
import {Group} from './components/Group.js';
import {Burger} from './components/Burger.js';
import {Nav} from './components/Nav.js';
import {SmartCursor} from './components/SmartCursor.js';
import {Content} from './components/Content.js';
import {useBodyClass} from './hooks/useBodyClass.js';

/*

Store

*/

import {useStore,Store,StoreRouter} from './store';
import {routes} from './store/transformer.js';

/*

Pages

*/

import {Project} from './pages/Project.js';
import {Gallery} from './pages/Gallery.js';
import {Home} from './pages/Home.js';
import {Profile} from './pages/Profile.js';
import {Index} from './pages/Index.js';
import {Basic} from './pages/Basic.js';



/*

Styles

*/

import './App.scss';

/*

Assets

*/

function BodyClass({className}){

    useBodyClass(className);

    return <div />

}






function Meta({data}){

  if(!data) return null;

  let matchKey = Object.keys(data).filter((k)=>(window.location.href.match(k))).pop();
  if(!matchKey) return null;

  let metaData = data[matchKey];

  return <Helmet>
           
            <title>{metaData.title}</title>
            <meta
            name="description"
            content={metaData.description}  />

        </Helmet>


}



function App() {

 

  return (
    <Presence isVisible={true}>
    <TouchClass>
          <StoreRouter routes={routes}>
          <Store>
            {
              (store) => {
            
                return (
                <div>

                <div className="Site-strapline">
                  <Link to={'/'}>ReWeave: Textile Waste Transformed</Link>
                </div>
                <div className="Site-info">
                  <Link to={store.SHOWINFO ? '/' : '/information/'}><AnimatedText text={store.SHOWINFO ? 'Close' : 'Information'} /></Link>
                </div>

                <div className="Site-address">
                  2nd Floor, Eagle House, Hastings, <a target="_blank" rel="nofollow" href="https://goo.gl/maps/YiuYzEPtLLsfxpZj6">TN34 1DJ</a>
                </div>

                <div className="Site-contact">
                  <ul className="List List--contact">
                    <li><a href="mailto:info@reweave.co.uk">info@reweave.co.uk</a></li>
                    <li><a target="_blank" rel="nofollow" href="https://instagram.com/re_weave_">Instagram</a></li>
                  </ul>
                </div>

                <BodyClass className={`Body--page`} />

              
                  {/* Canvas */}
                  <div className="Site-canvas">

                   <ReweaveLogo />

                  </div>
                  {/* End Canvas */}

                  {/*<div className="Site-bg-wrap">
                    <div className="Site-bg" />
                  </div>*/}

               
                 <Presence className="Layer Layer--project" isVisible={store.SHOWINFO}>
                    <Basic>
                        <div className="Page-story">
                        <div>
                          <div className="Content Content--story">
                          <p>ReWeave is a novel approach to exploring how fabric waste  
  can be transformed into design-led woven textiles on an 
  industrial scale to meet the increasing demand for circularity  
  in designing fashion and&nbsp;textiles.</p>

  <p>Led by textile designer <a target="_blank" rel="nofollow" href="https://www.kirstymcdougall.co.uk/">Kirsty McDougall</a>, ReWeave is a  
  Hastings-based design studio specialising in woven textiles and 
  product, and supported by the <a target="_blank" rel="nofollow" href="https://bftt.org.uk/">BFTT</a>. The project intends to 
  develop a viable business model for a more circular approach to 
  design and fabrication, and to analyse the environmental impact 
  of repurposing fabrics at an industrial&nbsp;scale.</p>

  <p>By exploring new models of textile design, ReWeave aspires to 
  serve as a blueprint for ideas about reuse and repurposing for 
  manufacturers and brands, spearheading industry&nbsp;change.</p>
                          </div>
                        </div>
                        </div>
                    </Basic>
                  </Presence> 

                </div>
                )
              }
            }
          </Store>
          </StoreRouter>

    </TouchClass>
    </Presence>
  );
}



export default App;
