
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import {useStore,Store,StoreRouter} from '../store';

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/effect-fade/effect-fade.min.css"
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"

// import Swiper core and required modules
import SwiperCore, {
  EffectFade,Navigation,Pagination
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([EffectFade,Navigation,Pagination]);



export const FadeSwiper = ({slides,onInit=()=>{}}) => {
  
  const {setCursor,unsetCursor} = useStore();
  const [swiper, setSwiper] = useState(null);
  
  return (
    <>
    <div onClick={()=>{swiper.slidePrev()}}
    onMouseEnter={()=>{
      setCursor('prev');
    }}
    onMouseLeave={()=>{
      unsetCursor();
    }}
    className="Swiper-prev">

    </div>
    <div onClick={()=>{swiper.slideNext()}}
    onMouseEnter={()=>{
      setCursor('next');
    }}
    onMouseLeave={()=>{
      unsetCursor();
    }}
    className="Swiper-next">
  
    </div>
    <Swiper onSwiper={(api)=>{
      setSwiper(api);
      if(onInit) onInit({swiper:api});
    }} spaceBetween={30} loop={true} effect={'fade'} navigation={false} pagination={{
  "clickable": true
}} className="mySwiper">
  {slides.map(
    (slide,s_ix) => <SwiperSlide key={s_ix}>{slide}</SwiperSlide>
  )}
  </Swiper>
    </>
  )
}